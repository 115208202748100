import { FunctionComponent, ChangeEvent, useState } from "react";
import { Dayjs } from "dayjs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DateTimePicker } from "@mui/x-date-pickers";
import { gql, useMutation } from "@apollo/client";
import Dropzone from "react-dropzone";
import {
  JesprRoute,
  JesprRouteType,
  JesprRouteDataPoint,
  JesprRouteWayPoint,
} from "../Route/Route";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { routeFromGPX } from "../../utils/gpx";
import { GET_MY_ROUTES } from "../Routes";

interface CreateRouteData {
  createRoute: JesprRoute;
}

interface CreateRouteVars {
  name: string;
  type: JesprRouteType;
  start?: string;
  distance: number;
  duration: number;
  dataPoints: JesprRouteDataPoint[];
  wayPoints: JesprRouteWayPoint[];
}

const CREATE_ROUTE = gql`
  mutation CreateRoute(
    $name: String!
    $type: RouteType!
    $start: String
    $distance: Int!
    $duration: Int!
    $dataPoints: [RouteDataPointInput]
    $wayPoints: [RouteWayPointInput]
  ) {
    createRoute(
      name: $name
      type: $type
      start: $start
      distance: $distance
      duration: $duration
      dataPoints: $dataPoints
      wayPoints: $wayPoints
    ) {
      id
      name
      start
      duration
      distance
      elevationGain
      dataPointsPolyline
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(6),
    },
    dropzone: {
      textAlign: "center",
      "&:hover": {
        backgroundColor: "WhiteSmoke",
        cursor: "pointer",
      },
    },
    placeholder: {
      marginTop: theme.spacing(4),
      textAlign: "center",
      color: "gray",
    },
  }),
);

interface Props {
  isOpen: boolean;
  onClose(): void;
}

const CreateRouteDialog: FunctionComponent<Props> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [routeType, setRouteType] = useState(JesprRouteType.Route);
  const [start, setStart] = useState<Dayjs | null>(null);
  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState(0);
  const [dataPoints, setDataPoints] = useState<JesprRouteDataPoint[]>([]);
  const [wayPoints, setWayPoints] = useState<JesprRouteWayPoint[]>([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [createRoute, { error: createRouteError }] = useMutation<
    CreateRouteData,
    CreateRouteVars
  >(CREATE_ROUTE, {
    variables: {
      name,
      type: routeType,
      start: start ? start.valueOf().toString() : undefined,
      distance,
      duration,
      dataPoints,
      wayPoints,
    },
    refetchQueries: [GET_MY_ROUTES],
    onCompleted: () => {
      handleClose();
      setIsConfirmationOpen(true);
    },
    onError: (err) => {
      setError(err);
    },
  });

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const fileAsText = reader.result;
        if (typeof fileAsText !== "string") {
          throw Error("text is not a string");
        }
        const route = await routeFromGPX(fileAsText);
        setName(name === "" ? route.name : `${name} - ${route.name}`);
        setDistance(route.distance);
        setDuration(route.duration);
        setDataPoints(route.dataPoints);
        setWayPoints(route.wayPoints);
      };
      reader.readAsText(file);
    });
  };

  const handleConfirmationClose = () => {
    setIsConfirmationOpen(false);
  };

  const handleErrorClose = () => {
    setError(null);
  };

  const handleClose = () => {
    setName("");
    setRouteType(JesprRouteType.Route);
    setStart(null);
    setDataPoints([]);
    setWayPoints([]);
    onClose();
  };

  if (createRouteError) {
    return <ErrorMessage error={createRouteError} />;
  }

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>Create Route</DialogTitle>
        <DialogContent>
          <Select
            variant="standard"
            fullWidth
            value={routeType}
            onChange={(e) => {
              setRouteType(e.target.value as JesprRouteType);
            }}
            className={classes.input}
          >
            <MenuItem value={JesprRouteType.Route}>Import Route</MenuItem>
            <MenuItem value={JesprRouteType.Race}>Create Race</MenuItem>
          </Select>

          <TextField
            variant="standard"
            id="name"
            label="Name"
            fullWidth
            autoFocus
            value={name}
            onChange={handleNameChange}
            className={classes.input}
          />

          <DateTimePicker
            label={`Start Time${
              routeType === JesprRouteType.Route ? " (Optional)" : ""
            }`}
            value={start}
            onChange={(date) => {
              setStart(date);
            }}
            className={classes.input}
          />

          <div className={classes.input}>
            {dataPoints.length > 0 ? (
              <>
                <Typography paragraph style={{ textAlign: "center" }}>
                  <strong>{dataPoints.length}</strong> data points imported from
                  GPX file
                </Typography>
                {wayPoints.length > 0 && (
                  <Typography paragraph style={{ textAlign: "center" }}>
                    <strong>{wayPoints.length}</strong> way points imported from
                    GPX file
                  </Typography>
                )}
              </>
            ) : (
              <div className={classes.dropzone}>
                <Dropzone multiple={false} onDrop={handleDrop}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                      {...getRootProps()}
                      className={`dropzone${
                        isDragActive ? " dropzone--isActive" : ""
                      }`}
                    >
                      <input data-testid="file-input" {...getInputProps()} />
                      <Typography className={classes.placeholder}>
                        Drop GPX file here to import route
                      </Typography>
                    </div>
                  )}
                </Dropzone>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            color="primary"
            disabled={
              name.length < 2 ||
              (routeType === JesprRouteType.Race && !start) ||
              dataPoints.length === 0
            }
            onClick={() => {
              createRoute();
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={isConfirmationOpen}
        message="Route created"
        autoHideDuration={4000}
        onClose={handleConfirmationClose}
      />

      <Snackbar
        open={Boolean(error)}
        message={error ? error : ""}
        onClose={handleErrorClose}
      />
    </>
  );
};

export default CreateRouteDialog;
