import { FunctionComponent, MouseEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import { gql, useQuery, useMutation } from "@apollo/client";
import InfiniteScroll from "react-infinite-scroller";
import ActivitiesList from "../activities/ActivitiesList";
import { JesprActivity } from "../activities/Activity/Activity";
import { JesprRoute } from "../routes/Route/Route";
import { JesprWorkout } from "../workouts/Workout/Workout";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Layout from "../Layout/Layout";
import Loading from "../Loading/Loading";

const PAGE_SIZE = 24;

interface JesprPlatform {
  id: string;
}

export interface JesprUser {
  id: string;
  givenName: string;
  name: string;
  height: number;
  weight: number;
  functionalThresholdPower?: number;
  picture: string;
  activities: JesprActivity[];
  routes: JesprRoute[];
  workouts: JesprWorkout[];
  friends: JesprUser[];
  friendRequests: JesprUser[];
  platforms: JesprPlatform[];
}

const GET_USER = gql`
  query GetUser($id: ID!, $offset: Int) {
    user(id: $id) {
      id
      name
      picture
      activities(first: ${PAGE_SIZE}, offset: $offset) {
        id
        name
        time
        start
        distance
        elevationGain
        dataPointsPolyline
      }
    }
    me {
      id
      friends {
        id
      }
    }
  }
`;

const REMOVE_FRIEND = gql`
  mutation RemoveFriend($id: ID!) {
    removeFriend(id: $id) {
      id
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    headline: {
      marginBottom: theme.spacing(4),
    },
    name: {
      display: "inline-flex",
    },
    buttonContainer: {
      textAlign: "right",
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      marginRight: theme.spacing(2),
    },
  }),
);

const User: FunctionComponent = () => {
  const classes = useStyles();
  const { userID } = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [hasMore, setHasMore] = useState(false);
  const { loading, error, data, fetchMore } = useQuery<
    { user: JesprUser; me: JesprUser },
    { id: string; offset: number }
  >(GET_USER, { variables: { id: userID || "", offset: 0 } });
  const [removeFriend, { error: removeFriendError }] = useMutation<
    JesprUser,
    { id: string }
  >(REMOVE_FRIEND, {
    variables: { id: userID || "" },
    refetchQueries: [GET_USER],
    onCompleted: () => {
      navigate("/friends");
    },
  });

  const isFriend = (me: JesprUser, id: string) => {
    for (const f of me.friends) {
      if (id === f.id) {
        return true;
      }
    }
    return false;
  };

  const handleOpenActions = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (removeFriendError) {
    return <ErrorMessage error={removeFriendError} />;
  }
  if (!data || !data.user || !data.me) {
    throw Error("missing data");
  }

  return (
    <Layout>
      <Grid container className={classes.headline}>
        <Grid item sm={6} className={classes.name}>
          {data.user.picture ? (
            <Avatar src={data.user.picture} className={classes.avatar} />
          ) : (
            <Avatar className={classes.avatar}>
              <PersonIcon />
            </Avatar>
          )}
          <Typography variant="h5" paragraph>
            {data.user.name}
          </Typography>
        </Grid>
        <Grid item sm={6} className={classes.buttonContainer}>
          {isFriend(data.me, data.user.id) && (
            <>
              <Button color="primary" onClick={handleOpenActions}>
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseActions}
              >
                <MenuItem
                  onClick={() => {
                    removeFriend();
                  }}
                >
                  <DeleteIcon /> Unfriend {data.user.name}
                </MenuItem>
              </Menu>
            </>
          )}
        </Grid>
      </Grid>

      {data.user.activities.length > 0 && (
        <>
          <Typography variant="h6" paragraph>
            Activities
          </Typography>
          <InfiniteScroll
            hasMore={hasMore}
            loader={<Loading key={0} />}
            loadMore={() => {
              fetchMore({
                variables: {
                  offset: data.user.activities.length,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) {
                    return prev;
                  }
                  if (fetchMoreResult.user.activities.length < PAGE_SIZE) {
                    setHasMore(false);
                  }
                  const next = {
                    ...prev,
                    user: {
                      ...prev.user,
                      activities: [
                        ...prev.user.activities,
                        ...fetchMoreResult.user.activities,
                      ],
                    },
                  };
                  return next;
                },
              });
            }}
          >
            <ActivitiesList activities={data.user.activities} />
          </InfiniteScroll>
        </>
      )}
    </Layout>
  );
};

export default User;
