import { FunctionComponent } from "react";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import { gql, useQuery, useMutation } from "@apollo/client";
import auth from "../Auth/Auth";
import { JesprUser } from "../User/User";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Layout from "../Layout/Layout";
import Loading from "../Loading/Loading";
import { platforms } from "../platforms/platforms";
import PlatformIcon from "../platforms/PlatformIcon";
import { GET_MY_ROUTES } from "../routes/Routes";

interface MeData {
  me: JesprUser;
}

const GET_ME = gql`
  query GetMe {
    me {
      id
      name
      height
      weight
      functionalThresholdPower
      picture
      platforms {
        id
      }
    }
  }
`;

const DISABLE_SYNC_PLATFORM = gql`
  mutation DisableSyncPlatform($id: PlatformID!) {
    disableSyncPlatform(id: $id) {
      id
      platforms {
        id
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    headline: {
      display: "inline-flex",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    avatar: {
      width: 48,
      height: 48,
      marginRight: theme.spacing(2),
    },
  }),
);

const Me: FunctionComponent = () => {
  const classes = useStyles();
  const { loading, error, data } = useQuery<MeData>(GET_ME);
  const [disableSyncPlatform, { error: disableSyncPlatformError }] =
    useMutation<{ id: string }, { id: string }>(DISABLE_SYNC_PLATFORM, {
      refetchQueries: [GET_MY_ROUTES],
    });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (disableSyncPlatformError) {
    return <ErrorMessage error={disableSyncPlatformError} />;
  }
  if (!data || !data.me) {
    throw Error("missing data");
  }

  return (
    <Layout>
      <div className={classes.headline}>
        {data.me.picture ? (
          <Avatar src={data.me.picture} className={classes.avatar} />
        ) : (
          <Avatar className={classes.avatar}>
            <PersonIcon />
          </Avatar>
        )}
        <Typography variant="h5" paragraph>
          {data.me.name}
        </Typography>
      </div>
      <Paper className={classes.container}>
        <Typography variant="h6" paragraph>
          Profile
        </Typography>
        <Typography>Height: {data.me.height.toFixed(2)} m</Typography>
        <Typography>Weight: {data.me.weight.toFixed(1)} kg</Typography>
        {Boolean(data.me.functionalThresholdPower) && (
          <Typography>
            Functional Threshold Power: {data.me.functionalThresholdPower} W
          </Typography>
        )}
      </Paper>

      <Paper className={classes.container}>
        <Typography variant="h6" paragraph>
          Sync Platforms
        </Typography>

        <Grid container spacing={2}>
          {platforms.map((platform) => (
            <Grid key={platform.id} item xs={12} md={6} lg={4} xl={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <PlatformIcon id={platform.id} />
                <Typography style={{ marginBottom: "1em" }}>
                  {platform.name}:{" "}
                  {data.me.platforms.find((p) => p.id === platform.id)
                    ? "Enabled"
                    : "Disabled"}
                </Typography>
                {data.me.platforms.find((p) => p.id === platform.id) ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      disableSyncPlatform({ variables: { id: platform.id } });
                    }}
                  >
                    Disable
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    href={`https://${
                      platform.authURI
                    }?client_id=${encodeURIComponent(
                      platform.authClientID,
                    )}&response_type=code&scope=${encodeURIComponent(
                      platform.authScope,
                    )}&redirect_uri=${encodeURIComponent(
                      `${window.location.protocol}//${window.location.host}/platforms/${platform.id}/auth/callback`,
                    )}`}
                  >
                    Enable
                  </Button>
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper className={classes.container}>
        <Typography variant="h6" paragraph>
          Actions
        </Typography>
        <Button variant="outlined" onClick={auth.logout}>
          Log Out
        </Button>
      </Paper>
    </Layout>
  );
};

export default Me;
