import { FunctionComponent, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers";
import { gql, useMutation } from "@apollo/client";
import { JesprRoute, JesprRouteType } from "../Route";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import { GET_MY_ROUTES } from "../../Routes";

interface UpdateRouteData {
  updateRoute: JesprRoute;
}

interface UpdateRouteVars {
  id: string;
  type?: JesprRouteType;
  start?: string | null;
}

const UPDATE_ROUTE = gql`
  mutation UpdateRoute($id: ID!, $type: RouteType, $start: String) {
    updateRoute(id: $id, type: $type, start: $start) {
      id
      type
      start
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(6),
    },
    dropzone: {
      textAlign: "center",
      "&:hover": {
        backgroundColor: "WhiteSmoke",
        cursor: "pointer",
      },
    },
    placeholder: {
      marginTop: theme.spacing(4),
      textAlign: "center",
      color: "gray",
    },
  }),
);

interface Props {
  isOpen: boolean;
  onClose(): void;
  route: JesprRoute;
}

const ScheduleRouteDialog: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  route,
}) => {
  const classes = useStyles();
  const [routeType, setRouteType] = useState(route.type);
  const [start, setStart] = useState<Dayjs | null>(
    route.start ? dayjs(parseInt(route.start, 10)) : null,
  );
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [updateRoute, { error: updateRouteError }] = useMutation<
    UpdateRouteData,
    UpdateRouteVars
  >(UPDATE_ROUTE, {
    variables: {
      id: route.id,
      type: routeType,
      start: start ? start.valueOf().toString() : null,
    },
    refetchQueries: [GET_MY_ROUTES],
    onCompleted: () => {
      handleClose();
      setIsConfirmationOpen(true);
    },
    onError: (err) => {
      setError(err);
    },
  });

  const handleConfirmationClose = () => {
    setIsConfirmationOpen(false);
  };

  const handleErrorClose = () => {
    setError(null);
  };

  const handleClose = () => {
    setRouteType(JesprRouteType.Route);
    setStart(null);
    onClose();
  };

  if (updateRouteError) {
    return <ErrorMessage error={updateRouteError} />;
  }

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>Schedule Route</DialogTitle>
        <DialogContent>
          <Select
            variant="standard"
            fullWidth
            value={routeType}
            onChange={(e) => {
              setRouteType(e.target.value as JesprRouteType);
            }}
            className={classes.input}
          >
            <MenuItem value={JesprRouteType.Route}>Route</MenuItem>
            <MenuItem value={JesprRouteType.Race}>Race</MenuItem>
          </Select>

          <TextField
            variant="standard"
            id="name"
            label="Name"
            fullWidth
            disabled
            value={route.name}
            className={classes.input}
          />

          <DateTimePicker
            label="Start Time"
            value={start}
            onChange={(date) => {
              setStart(date);
            }}
            className={classes.input}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            color="primary"
            disabled={routeType === JesprRouteType.Race && !start}
            onClick={() => {
              updateRoute();
            }}
          >
            Schedule
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={isConfirmationOpen}
        message="Route scheduled"
        autoHideDuration={4000}
        onClose={handleConfirmationClose}
      />

      <Snackbar
        open={Boolean(error)}
        message={error ? error : ""}
        onClose={handleErrorClose}
      />
    </>
  );
};

export default ScheduleRouteDialog;
