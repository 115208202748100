import { parseStringPromise } from "xml2js";
import { distanceBetween } from "./coordinates";
import {
  JesprActivity,
  JesprLap,
  JesprDataPoint,
} from "../activities/Activity/Activity";
import {
  JesprRoute,
  JesprRouteDataPoint,
  JesprRouteWayPoint,
  JesprRouteType,
} from "../routes/Route/Route";

export const activityFromGPX = async (gpx: string) => {
  const xml = await parseStringPromise(gpx);
  const name = extractName(xml);
  const laps: JesprLap[] = [];
  let dataPoints: JesprDataPoint[] = [];

  for (const trk of xml.gpx.trk) {
    for (const [i, seg] of trk.trkseg.entries()) {
      const lap: JesprLap = {
        index: i,
        finalTime: 0,
        finalDistance: 0,
        finalElevationGain: 0,
      };
      const lapDataPoints: JesprDataPoint[] = [];

      for (const pt of seg.trkpt) {
        if (!pt.time) {
          throw new Error(
            "Invalid activity due to missing timestamps in data points",
          );
        }

        let heartRate = 0;
        let temperature = 0;
        let cadence = 0;
        let power = 0;

        // GPXData (Cluetrust)
        if (pt.extensions && pt.extensions.length > 0) {
          const ext = pt.extensions[0];
          if (ext["gpxdata:hr"]) {
            heartRate = parseFloat(ext["gpxdata:hr"]);
          }
          if (ext["gpxdata:temp"]) {
            temperature = parseFloat(ext["gpxdata:temp"]) + 273.15;
          }
          if (ext.cadence) {
            cadence = parseFloat(ext.cadence);
          }
          if (ext.power) {
            power = parseFloat(ext.power);
          }

          // TrackPointExtension (Garmin)
          if (
            ext["ns3:TrackPointExtension"] &&
            ext["ns3:TrackPointExtension"].length > 0
          ) {
            const garminExt = ext["ns3:TrackPointExtension"][0];
            if (garminExt["ns3:hr"] && garminExt["ns3:hr"].length > 0) {
              heartRate = parseFloat(garminExt["ns3:hr"][0]);
            }
            if (garminExt["ns3:atemp"] && garminExt["ns3:atemp"].length > 0) {
              temperature = parseFloat(garminExt["ns3:atemp"][0]) + 273.15;
            }
            if (garminExt["ns3:cad"] && garminExt["ns3:cad"].length > 0) {
              cadence = parseFloat(garminExt["ns3:cad"][0]) + 273.15;
            }
          }
        }

        const dp: JesprDataPoint = {
          lap: i,
          timestamp: new Date(pt.time).getTime().toString(),
          coordinates: [parseFloat(pt.$.lat), parseFloat(pt.$.lon)],
          elevation: parseFloat(pt.ele[0]),
          temperature,
          gradient: 0,
          power,
          cadence,
          heartRate,
          torqueEfficiency: {
            left: 0,
            right: 0,
          },
          pedalBalance: {
            left: 0,
            right: 0,
          },
          pedalSmoothness: {
            left: 0,
            right: 0,
          },
        };
        lapDataPoints.push(dp);
      }

      lapDataPoints.forEach((dp, i) => {
        if (i < lapDataPoints.length - 1) {
          const nextDP = lapDataPoints[i + 1];

          const timeDiff = Number(nextDP.timestamp) - Number(dp.timestamp);
          lap.finalTime += timeDiff;

          const distanceDiff = distanceBetween(
            dp.coordinates,
            nextDP.coordinates,
          );
          lap.finalDistance += distanceDiff;

          const elevationDiff = nextDP.elevation - dp.elevation;
          lap.finalElevationGain += elevationDiff > 0 ? elevationDiff : 0;

          if (timeDiff > 0) {
            lapDataPoints[i].speed = distanceDiff / (timeDiff / 1000);
          }
        }
      });

      lap.finalDistance = Math.round(lap.finalDistance);
      lap.finalElevationGain = Math.round(lap.finalElevationGain);

      laps.push(lap);
      dataPoints = dataPoints.concat(lapDataPoints);
    }
  }

  const activity: JesprActivity = {
    id: "",
    name,
    active: false,
    laps,
    dataPoints,
    dataPointsPolyline: "",
    start: new Date(0),
    distance: 0,
    time: 0,
    elevationGain: 0,
    platformSyncs: [],
    user: {
      id: "",
      givenName: "",
      name: "",
      height: 0,
      weight: 0,
      picture: "",
      activities: [],
      routes: [],
      workouts: [],
      friends: [],
      friendRequests: [],
      platforms: [],
    },
  };
  return activity;
};

export const gpxFromActivity = (activity: JesprActivity) => {
  let trkpts = "";
  for (const dp of activity.dataPoints) {
    trkpts += `
      <trkpt lat="${dp.coordinates[0]}" lon="${dp.coordinates[1]}">
        <ele>${dp.elevation}</ele>
        <time>${new Date(parseInt(dp.timestamp, 10)).toISOString()}</time>
        <extensions>
          <gpxdata:hr>${dp.heartRate}</gpxdata:hr>
          <cadence>${dp.cadence}</cadence>
          <power>${dp.power}</power>
          <gpxdata:temp>${dp.temperature - 273.15}</gpxdata:temp>
        </extensions>
      </trkpt>`;
  }

  const gpx = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<gpx xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.topografix.com/GPX/1/1" version="1.1" creator="Jespr Connects - https://www.jespr.io" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">
  <metadata>
    <name>${activity.name}</name>
    <link href="https://jespr.ch">
      <text>Jespr Connects</text>
    </link>
  </metadata>
  <trk>
    <trkseg>${trkpts}
    </trkseg>
  </trk>
</gpx>
`;
  return gpx;
};

export const routeFromGPX = async (gpx: string) => {
  const xml = await parseStringPromise(gpx);
  const name = extractName(xml);
  const dataPoints: JesprRouteDataPoint[] = [];
  const wayPoints: JesprRouteWayPoint[] = [];

  if (xml.gpx.rte) {
    for (const rte of xml.gpx.rte) {
      for (const pt of rte.rtept) {
        const dp: JesprRouteDataPoint = {
          coordinates: [parseFloat(pt.$.lat), parseFloat(pt.$.lon)],
          elevation: parseFloat(pt.ele[0]),
        };
        if (pt.time) {
          dp.timestamp = new Date(pt.time).getTime().toString();
        }
        dataPoints.push(dp);
      }
    }
  }

  if (dataPoints.length === 0 && xml.gpx.trk) {
    for (const trk of xml.gpx.trk) {
      for (const seg of trk.trkseg) {
        for (const pt of seg.trkpt) {
          const dp: JesprRouteDataPoint = {
            coordinates: [parseFloat(pt.$.lat), parseFloat(pt.$.lon)],
            elevation: parseFloat(pt.ele[0]),
          };
          if (pt.time) {
            dp.timestamp = new Date(pt.time).getTime().toString();
          }
          dataPoints.push(dp);
        }
      }
    }
  }

  let distance = 0;
  dataPoints.forEach((dp, i) => {
    if (i < dataPoints.length - 1) {
      distance += distanceBetween(
        dp.coordinates,
        dataPoints[i + 1].coordinates,
      );
    }
  });

  if (xml.gpx.wpt) {
    for (const wpt of xml.gpx.wpt) {
      const coordinates = [parseFloat(wpt.$.lat), parseFloat(wpt.$.lon)];
      const wp: JesprRouteWayPoint = {
        coordinates,
      };
      if (wpt.name && wpt.name.length > 0) {
        wp.name = wpt.name[0];
      }
      if (wpt.type && wpt.type.length > 0) {
        wp.type = wpt.type[0];
      }
      wayPoints.push(wp);
    }
  }

  const route: JesprRoute = {
    id: "",
    name,
    type: JesprRouteType.Route,
    dataPoints,
    dataPointsPolyline: "",
    wayPoints,
    distance,
    duration: 0,
    elevationGain: 0,
    source: "JESPR",
    user: {
      id: "",
      givenName: "",
      name: "",
      height: 0,
      weight: 0,
      picture: "",
      activities: [],
      routes: [],
      workouts: [],
      friends: [],
      friendRequests: [],
      platforms: [],
    },
    createdAt: "",
  };
  return route;
};

export const gpxFromRoute = (route: JesprRoute) => {
  let rtepts = "";
  for (const dp of route.dataPoints) {
    const timestamp = dp.timestamp
      ? `
        <time>${new Date(parseInt(dp.timestamp, 10)).toISOString()}</time>`
      : "";
    rtepts += `
    <rtept lat="${dp.coordinates[0]}" lon="${dp.coordinates[1]}">
      <ele>${dp.elevation}</ele>${timestamp}
    </rtept>`;
  }

  let wpts = "";
  for (const wp of route.wayPoints) {
    const name = wp.name
      ? `
    <name>${wp.name}</name>`
      : "";
    const wpType = wp.type
      ? `
    <type>${wp.type}</type>`
      : "";
    wpts += `
  <wpt lat="${wp.coordinates[0]}" lon="${wp.coordinates[1]}">${name}${wpType}
  </wpt>`;
  }

  const gpx = `<?xml version="1.0" encoding="UTF-8"?>
<gpx xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.topografix.com/GPX/1/1" version="1.1" creator="Jespr Connects - https://www.jespr.io" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">
  <metadata>
    <name>${route.name}</name>
    <link href="https://jespr.ch">
      <text>Jespr Connects</text>
    </link>
  </metadata>
  <rte>${rtepts}
  </rte>${wpts}
</gpx>
`;
  return gpx;
};

const extractName = (xml: any) => {
  const gpx = xml.gpx;
  if (gpx.metadata && gpx.metadata.length > 0) {
    const metadata = gpx.metadata[0];
    if (metadata.name && metadata.name.length > 0) {
      return metadata.name[0];
    }
  }
  if (gpx.trk && gpx.trk.length > 0) {
    const trk = gpx.trk[0];
    if (trk.name && trk.name.length > 0) {
      return trk.name[0];
    }
  }
  if (gpx.rte && gpx.rte.length > 0) {
    const rte = gpx.rte[0];
    if (rte.name && rte.name.length > 0) {
      return rte.name[0];
    }
  }
  return "";
};
