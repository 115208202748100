import { FunctionComponent, useState } from "react";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { gql, useQuery } from "@apollo/client";
import InfiniteScroll from "react-infinite-scroller";
import ActivitiesList from "./ActivitiesList";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Layout from "../Layout/Layout";
import Loading from "../Loading/Loading";
import { JesprUser } from "../User/User";
import CreateActivityDialog from "./CreateActivityDialog/CreateActivityDialog";

const PAGE_SIZE = 24;

export const GET_MY_ACTIVITIES = gql`
  query GetMyActivities($sort: String, $offset: Int) {
    me {
      id
      activities(sort: $sort, first: ${PAGE_SIZE}, offset: $offset) {
        id
        name
        time
        start
        distance
        elevationGain
        dataPointsPolyline
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headline: {
      marginBottom: theme.spacing(2),
    },
    selectContainer: {
      textAlign: "right",
    },
    fab: {
      position: "fixed",
      right: theme.spacing(4),
      bottom: theme.spacing(4),
    },
  }),
);

const Activities: FunctionComponent = () => {
  const classes = useStyles();
  const [sort, setSort] = useState("-start");
  const [hasMore, setHasMore] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { loading, error, data, fetchMore } = useQuery<
    { me: JesprUser },
    { sort: string; offset: number }
  >(GET_MY_ACTIVITIES, {
    variables: { sort, offset: 0 },
    fetchPolicy: "cache-and-network",
  });

  const handleSortChange = (
    event: SelectChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    setSort(event.target.value as string);
    setHasMore(true);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!data || !data.me) {
    throw Error("missing data");
  }

  return (
    <>
      <Layout>
        <Grid
          container
          justifyContent="space-between"
          className={classes.headline}
        >
          <Grid item sm={6}>
            <Typography variant="h5">My Activities</Typography>
          </Grid>
          <Grid item sm={6} className={classes.selectContainer}>
            <FormControl variant="standard">
              <InputLabel id="sort-label">Order By</InputLabel>
              <Select
                labelId="sort-label"
                id="sort"
                variant="standard"
                value={sort as ""}
                onChange={handleSortChange}
              >
                <MenuItem value="-start">Date Created (newest first)</MenuItem>
                <MenuItem value="start">Date Created (oldest first)</MenuItem>
                <MenuItem value="name">Name (A first)</MenuItem>
                <MenuItem value="-name">Name (Z first)</MenuItem>
                <MenuItem value="distance">Distance (shortest first)</MenuItem>
                <MenuItem value="-distance">Distance (longest first)</MenuItem>
                <MenuItem value="elevationGain">
                  Elevation Gain (lowest first)
                </MenuItem>
                <MenuItem value="-elevationGain">
                  Elevation Gain (highest first)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <InfiniteScroll
          hasMore={hasMore}
          loader={<Loading key={0} />}
          loadMore={() => {
            fetchMore({
              variables: { sort, offset: data.me.activities.length },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }
                if (fetchMoreResult.me.activities.length < PAGE_SIZE) {
                  setHasMore(false);
                }
                const next = {
                  ...prev,
                  me: {
                    ...prev.me,
                    activities: [
                      ...prev.me.activities,
                      ...fetchMoreResult.me.activities,
                    ],
                  },
                };
                return next;
              },
            });
          }}
        >
          <ActivitiesList activities={data.me.activities} />
        </InfiniteScroll>
      </Layout>

      <Fab
        color="primary"
        aria-label="Create Activity"
        className={classes.fab}
        onClick={handleDialogOpen}
      >
        <AddIcon />
      </Fab>

      <CreateActivityDialog isOpen={isDialogOpen} onClose={handleDialogClose} />
    </>
  );
};

export default Activities;
