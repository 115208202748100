import { FunctionComponent } from "react";
import { gql, useQuery } from "@apollo/client";
import AdminApolloClient from "./adminClient";
import Loading from "../Loading/Loading";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import styles from "./tablestyle.module.css";

export const GET_USER_INFOS = gql`
  query GetUserInfos {
    users {
      id
      email
      totalDistance
      devices {
        id
        imei
        deviceId
      }
    }
  }
`;

interface UserInfo {
  id: string;
  email: string;
  totalDistance: number;
  devices: Device[];
}

interface Device {
  id: string;
  deviceId: string;
  imei: string;
}

const UserInfoTable: FunctionComponent = () => {
  const { loading, error, data } = useQuery(GET_USER_INFOS, {
    client: AdminApolloClient,
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!data) {
    throw Error("missing data");
  }

  return (
    <div>
      <h1>User Info</h1>
      <table
        className={styles.tablestyle}
        style={{ borderCollapse: "collapse" }}
      >
        <thead style={{ borderBottom: "2px solid white" }}>
          <tr>
            <th>Email</th>
            <th>Total Distance</th>
            <th>Devices (Device ID, IMEI)</th>
          </tr>
        </thead>
        <tbody>
          {data.users.map((user: UserInfo) => (
            <tr
              style={{ borderBottom: "2px solid white", margin: "10px" }}
              key={user.id}
            >
              <td>{user.email}</td>
              <td>{(user.totalDistance / 1000).toFixed(2)} km</td>
              <td>
                {user.devices.map((device: Device) => (
                  <div key={device.id}>
                    <code>{[device.deviceId, device.imei].join(" ")}</code>
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserInfoTable;
