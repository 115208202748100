import { FunctionComponent } from "react";
import Header from "./Header/Header";

const Layout: FunctionComponent = ({ children }) => (
  <>
    <Header />

    <div className="jespr-main-content">{children}</div>
  </>
);

export default Layout;
