import { FunctionComponent, useEffect } from "react";
import { useParams, useSearchParams, Navigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Loading from "../Loading/Loading";

const ENABLE_SYNC_PLATFORM = gql`
  mutation EnableSyncPlatform(
    $id: PlatformID!
    $redirectUri: String!
    $authCode: String!
  ) {
    enableSyncPlatform(
      id: $id
      redirectUri: $redirectUri
      authCode: $authCode
    ) {
      id
      platforms {
        id
      }
    }
  }
`;

const PlatformAuthCallback: FunctionComponent = () => {
  const { platformID } = useParams();
  const [searchParams] = useSearchParams();
  const [enableSyncPlatform, { loading, error, data }] = useMutation<
    { id: string },
    { id: string; redirectUri: string; authCode: string }
  >(ENABLE_SYNC_PLATFORM);

  const redirectUri = window.location.href.split("?")[0];
  const authCode = searchParams.get("code");

  useEffect(() => {
    if (platformID && redirectUri && authCode) {
      enableSyncPlatform({
        variables: { id: platformID, redirectUri, authCode },
      });
    }
  }, [enableSyncPlatform, platformID, redirectUri, authCode]);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!data) {
    return null;
  }

  return <Navigate to="/me" replace />;
};

export default PlatformAuthCallback;
