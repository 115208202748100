import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import { gql, useQuery } from "@apollo/client";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { JesprUser } from "../../User/User";
import Logo from "./Logo/Logo";

interface MeData {
  me: JesprUser;
}

const GET_ME = gql`
  query GetMe {
    me {
      id
      givenName
      email
    }
  }
`;

const Header: FunctionComponent = () => {
  const { loading, error, data } = useQuery<MeData>(GET_ME);

  if (loading) {
    return null;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!data || !data.me) {
    throw Error("missing data");
  }

  return (
    <AppBar position="sticky" style={{ zIndex: 99999 }}>
      <Toolbar style={{ justifyContent: "space-between" }}>
        <div>
          <Logo />
          <Button
            component={({ innerRef, ...props }) => (
              <Link to="/activities" {...props} />
            )}
          >
            Activities
          </Button>
          <Button
            component={({ innerRef, ...props }) => (
              <Link to="/routes" {...props} />
            )}
          >
            Routes
          </Button>
        </div>
        <div style={{ justifyContent: "flex-end" }}>
          <Button
            component={({ innerRef, ...props }) => <Link to="/me" {...props} />}
          >
            {data.me.givenName}
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
