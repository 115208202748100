import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";
import auth from "../Auth/Auth";

const Callback: FunctionComponent = () => {
  auth.handleAuthentication();
  return <Navigate to="/" />;
};

export default Callback;
