import { PureComponent } from "react";

interface State {
  error: Error | null;
}

class ErrorBoundary extends PureComponent<{}, State> {
  readonly state: State = { error: null };

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  handleClick = () => {
    window.location.replace("/");
  };

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <div style={{ textAlign: "center" }}>
          <h1>Something went wrong...</h1>
          <pre>{error}</pre>
          <br />
          <button onClick={this.handleClick}>Reload Page</button>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
