import { FunctionComponent, MouseEvent, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fab from "@mui/material/Fab";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import EventIcon from "@mui/icons-material/Event";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { gql, useQuery, useMutation } from "@apollo/client";
import ScheduleRouteDialog from "./ScheduleRouteDialog/ScheduleRouteDialog";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import Layout from "../../Layout/Layout";
import Loading from "../../Loading/Loading";
import { JesprUser } from "../../User/User";
import { gpxFromRoute } from "../../utils/gpx";
import Map from "./Map";
import Charts from "./Charts";
import { GET_MY_ROUTES } from "../Routes";

export enum JesprRouteType {
  Route = "ROUTE",
  Race = "RACE",
}

export interface JesprRouteDataPoint {
  coordinates: number[];
  elevation: number;
  timestamp?: string;
  instruction?: string;
  turnType?: number;
}

export interface JesprRouteWayPoint {
  index?: number;
  coordinates: number[];
  distance?: number;
  name?: string;
  type?: string;
}

export interface JesprRoute {
  id: string;
  name: string;
  type: JesprRouteType;
  distance: number;
  duration: number;
  elevationGain: number;
  start?: string;
  source: string;
  dataPoints: JesprRouteDataPoint[];
  dataPointsPolyline: string;
  wayPoints: JesprRouteWayPoint[];
  user: JesprUser;
  createdAt: string;
}

interface RouteData {
  route: JesprRoute;
  me: JesprUser;
}

interface RouteVars {
  id: string;
  source?: string;
}

const GET_ROUTE = gql`
  query GetRoute($id: ID!, $source: PlatformID) {
    route(id: $id, source: $source) {
      id
      type
      name
      distance
      duration
      start
      source
      dataPoints {
        coordinates
        elevation
      }
      wayPoints {
        coordinates
        name
        type
      }
    }
    me {
      id
    }
  }
`;

const DELETE_ROUTE = gql`
  mutation DeleteRoute($id: ID!) {
    deleteRoute(id: $id)
  }
`;

let clientHeight = 0;
if (document.documentElement) {
  clientHeight = document.documentElement.clientHeight;
}
const windowHeight = Math.max(clientHeight, window.innerHeight || 0);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {
      height: windowHeight - 200,
      marginBottom: theme.spacing(5),
    },
    active: {
      color: theme.palette.primary.main,
    },
    deleteItem: {
      color: "red",
    },
    buttonContainer: {
      textAlign: "right",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      position: "fixed",
      bottom: theme.spacing(4),
      left: theme.spacing(4),
      zIndex: 3,
    },
  }),
);

const Route: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { routeID } = useParams();
  const [searchParams] = useSearchParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const source = searchParams.get("source");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { loading, error, data } = useQuery<RouteData, RouteVars>(GET_ROUTE, {
    variables: { id: routeID || "", source: source ? source : undefined },
  });
  const [deleteRoute, { error: deleteError }] = useMutation<
    boolean,
    { id: string }
  >(DELETE_ROUTE, {
    variables: { id: routeID || "" },
    refetchQueries: [GET_MY_ROUTES],
    onCompleted: () => {
      navigate("/routes");
    },
  });

  const handleOpenActions = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDownload = (route: JesprRoute) => {
    const gpx = gpxFromRoute(route);
    const element = document.createElement("a");
    const file = new Blob([gpx], { type: "application/gpx+xml" });
    element.href = URL.createObjectURL(file);
    element.download = `${route.name}.gpx`;
    element.click();
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (deleteError) {
    return <ErrorMessage error={deleteError} />;
  }
  if (!data || !data.route || !data.me) {
    throw Error("missing data");
  }

  return (
    <>
      <Layout>
        <>
          <Grid container justifyContent="space-between">
            <Grid item sm={6}>
              <Typography variant="h5" paragraph>
                {data.route.name}
              </Typography>
            </Grid>
            <Grid item sm={6} className={classes.buttonContainer}>
              <Button color="primary" onClick={handleOpenActions}>
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseActions}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseActions();
                    handleDialogOpen();
                  }}
                >
                  <EventIcon className={classes.icon} /> Schedule
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseActions();
                    handleDownload(data.route);
                  }}
                >
                  <CloudDownloadIcon className={classes.icon} /> Download as GPX
                </MenuItem>
                {(!data.route.source || data.route.source === "JESPR") && (
                  <MenuItem
                    className={classes.deleteItem}
                    onClick={() => {
                      handleCloseActions();
                      deleteRoute();
                    }}
                  >
                    <DeleteIcon className={classes.icon} /> Delete
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>

          <div className={classes.mapContainer}>
            <Map route={data.route} />
          </div>

          <Charts route={data.route} />
        </>
      </Layout>

      <Fab
        aria-label="Go back"
        className={classes.backButton}
        onClick={() => {
          navigate("/routes");
        }}
      >
        <ArrowBackIcon />
      </Fab>

      <ScheduleRouteDialog
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        route={data.route}
      />
    </>
  );
};

export default Route;
