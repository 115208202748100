import { FunctionComponent } from "react";
import Typography from "@mui/material/Typography";
import {
  Area,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { distanceBetween } from "../../utils/coordinates";
import { JesprRoute } from "./Route";

interface ChartDataPoint {
  distance: number;
  elevation: number;
}

const CHART_HEIGHT = 300;

interface Props {
  route: JesprRoute;
}

const Charts: FunctionComponent<Props> = ({ route }) => {
  const data: ChartDataPoint[] = [];
  let distance = 0;
  let showElevation = false;
  route.dataPoints.forEach((dp, i) => {
    if (!showElevation && dp.elevation) {
      showElevation = true;
    }
    data[i] = {
      distance: distance / 1000,
      elevation: dp.elevation,
    };
    if (i < route.dataPoints.length - 1) {
      distance += distanceBetween(
        dp.coordinates,
        route.dataPoints[i + 1].coordinates,
      );
    }
  });

  return (
    <>
      {showElevation && (
        <>
          <Typography variant="h6" paragraph>
            Elevation
          </Typography>
          <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
            <ComposedChart data={data} syncId="sync">
              <XAxis
                type="number"
                dataKey="distance"
                name="Distance"
                unit=" km"
                domain={[0, "dataMax"]}
                tickFormatter={(t) => Math.round(t).toString()}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                dataKey="elevation"
                name="Elevation"
                unit=" hm"
                domain={["dataMin", "dataMax"]}
                tickFormatter={(t) => Math.round(t).toString()}
              />
              <Area
                yAxisId="right"
                type="monotone"
                dataKey="elevation"
                name="Elevation"
                stroke="#007047"
                fill="rgba(192,192,192,0.3)"
                strokeWidth={0}
              />
              <Legend />
              <Tooltip
                labelFormatter={(v) => `${Number(v).toFixed(2)} km`}
                formatter={(v) => `${Math.round(Number(v))} hm`}
                contentStyle={{ backgroundColor: "DarkSlateGray" }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}
    </>
  );
};

export default Charts;
