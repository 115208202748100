import { FunctionComponent, ChangeEvent, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { gql, useMutation } from "@apollo/client";
import Dropzone from "react-dropzone";
import { GET_MY_ACTIVITIES } from "../Activities";
import { JesprActivity, JesprLap, JesprDataPoint } from "../Activity/Activity";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { activityFromGPX } from "../../utils/gpx";

interface CreateActivityData {
  createActivity: JesprActivity;
}

interface CreateActivityVars {
  name: string;
  laps: JesprLap[];
  dataPoints: JesprDataPoint[];
}

const CREATE_ACTIVITY = gql`
  mutation CreateActivity(
    $name: String!
    $laps: [LapInput]
    $dataPoints: [DataPointInput]
  ) {
    createActivity(name: $name, laps: $laps, dataPoints: $dataPoints) {
      id
      name
      active
      time
      start
      distance
      elevationGain
      dataPointsPolyline
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(6),
    },
    dropzone: {
      textAlign: "center",
      "&:hover": {
        backgroundColor: "WhiteSmoke",
        cursor: "pointer",
      },
    },
    placeholder: {
      marginTop: theme.spacing(4),
      textAlign: "center",
      color: "gray",
    },
  }),
);

interface Props {
  isOpen: boolean;
  onClose(): void;
}

const CreateActivityDialog: FunctionComponent<Props> = ({
  isOpen,
  onClose,
}) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [laps, setLaps] = useState<JesprLap[]>([]);
  const [dataPoints, setDataPoints] = useState<JesprDataPoint[]>([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [createActivity, { error: createActivityError }] = useMutation<
    CreateActivityData,
    CreateActivityVars
  >(CREATE_ACTIVITY, {
    variables: {
      name,
      laps,
      dataPoints,
    },
    refetchQueries: [GET_MY_ACTIVITIES],
    onCompleted: () => {
      handleClose();
      setIsConfirmationOpen(true);
    },
    onError: (err) => {
      setError(err);
    },
  });

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const fileAsText = reader.result;
        if (typeof fileAsText !== "string") {
          throw Error("text is not a string");
        }
        const activity = await activityFromGPX(fileAsText);
        setName(name === "" ? activity.name : `${name} - ${activity.name}`);
        setLaps(activity.laps);
        setDataPoints(activity.dataPoints);
      };
      reader.readAsText(file);
    });
  };

  const handleConfirmationClose = () => {
    setIsConfirmationOpen(false);
  };

  const handleErrorClose = () => {
    setError(null);
  };

  const handleClose = () => {
    setName("");
    setLaps([]);
    setDataPoints([]);
    onClose();
  };

  if (createActivityError) {
    return <ErrorMessage error={createActivityError} />;
  }

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>Create Activity</DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            id="name"
            label="Name"
            fullWidth
            autoFocus
            value={name}
            onChange={handleNameChange}
            className={classes.input}
          />

          <div className={classes.input}>
            {dataPoints.length ? (
              <Typography paragraph style={{ textAlign: "center" }}>
                <strong>{dataPoints.length}</strong> data points imported from
                GPX file
              </Typography>
            ) : (
              <div className={classes.dropzone}>
                <Dropzone multiple={false} onDrop={handleDrop}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                      {...getRootProps()}
                      className={`dropzone${
                        isDragActive ? " dropzone--isActive" : ""
                      }`}
                    >
                      <input data-testid="file-input" {...getInputProps()} />
                      <Typography className={classes.placeholder}>
                        Drop GPX file here to import activity
                      </Typography>
                    </div>
                  )}
                </Dropzone>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            color="primary"
            disabled={name.length < 2 || dataPoints.length === 0}
            onClick={() => {
              createActivity();
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={isConfirmationOpen}
        message="Activity created"
        autoHideDuration={4000}
        onClose={handleConfirmationClose}
      />

      <Snackbar
        open={Boolean(error)}
        message={error ? error : ""}
        onClose={handleErrorClose}
      />
    </>
  );
};

export default CreateActivityDialog;
