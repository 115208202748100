import { FunctionComponent, useState } from "react";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { gql, useQuery } from "@apollo/client";
import RoutesList from "./RoutesList";
import CreateRouteDialog from "./CreateRouteDialog/CreateRouteDialog";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Layout from "../Layout/Layout";
import Loading from "../Loading/Loading";
import { JesprUser } from "../User/User";

export const GET_MY_ROUTES = gql`
  query GetMyRoutes {
    me {
      id
      routes {
        id
        name
        start
        distance
        elevationGain
        dataPointsPolyline
        source
        createdAt
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headline: {
      marginBottom: theme.spacing(2),
    },
    selectContainer: {
      textAlign: "right",
    },
    fab: {
      position: "fixed",
      right: theme.spacing(4),
      bottom: theme.spacing(4),
    },
  }),
);

const Routes: FunctionComponent = () => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { loading, error, data } = useQuery<{ me: JesprUser }>(GET_MY_ROUTES, {
    fetchPolicy: "cache-and-network",
  });
  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (!data || !data.me) {
    throw Error("missing data");
  }

  return (
    <>
      <Layout>
        <Grid
          container
          justifyContent="space-between"
          className={classes.headline}
        >
          <Grid item>
            <Typography variant="h5">My Routes</Typography>
          </Grid>
        </Grid>
        <RoutesList routes={data.me.routes} />
      </Layout>

      <Fab
        color="primary"
        aria-label="Create "
        className={classes.fab}
        onClick={handleDialogOpen}
      >
        <AddIcon />
      </Fab>

      <CreateRouteDialog isOpen={isDialogOpen} onClose={handleDialogClose} />
    </>
  );
};

export default Routes;
