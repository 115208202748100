import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import SpeedIcon from "@mui/icons-material/Speed";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { JesprWorkout } from "./Workout/Workout";
import PlatformIcon from "../platforms/PlatformIcon";

const MAPBOX_STYLE = "robertoehler/ckses42lz4sw117nzs988o03v";
const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 400,
    },
    placeholder: {
      height: 400,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    icon: {
      height: "0.5em",
    },
    active: {
      color: theme.palette.primary.main,
    },
  }),
);

interface Props {
  workouts: JesprWorkout[];
}

const WorkoutsList: FunctionComponent<Props> = ({ workouts }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {workouts.map(
        (w) =>
          w.id !== "null" && (
            <Grid key={w.id} item xs={12} md={6} lg={4} xl={3}>
              <Link
                to={{
                  pathname: `/workouts/${w.id}`,
                  search: `?source=${w.source}`,
                }}
              >
                <Card className="jespr-hoverable">
                  {w.route?.dataPointsPolyline ? (
                    <CardMedia
                      title={w.name}
                      image={`https://api.mapbox.com/styles/v1/${MAPBOX_STYLE}/static/path-2.5+00f096-0.6(${encodeURIComponent(
                        w.route.dataPointsPolyline,
                      )})/auto/600x400@2x?access_token=${mapboxAccessToken}`}
                      className={classes.media}
                    />
                  ) : (
                    <div className={classes.placeholder} />
                  )}
                  <CardHeader
                    title={w.name}
                    subheader={
                      w.start
                        ? new Date(Number(w.start)).toLocaleDateString()
                        : "No start date"
                    }
                    action={<PlatformIcon id={w.source} />}
                  />
                  <CardContent>
                    {w.route && (
                      <>
                        <Typography>
                          <PinDropIcon className={classes.icon} />
                          {(w.route.distance / 1000).toFixed(2)} km
                        </Typography>
                        <Typography>
                          <FilterHdrIcon className={classes.icon} />{" "}
                          {Math.round(w.route.elevationGain)} hm
                        </Typography>
                      </>
                    )}
                    {Boolean(w.tss) && (
                      <Typography>
                        <SpeedIcon className={classes.icon} />
                        {w.tss} TSS
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ),
      )}
    </Grid>
  );
};

export default WorkoutsList;
