import { FunctionComponent } from "react";
import Map, {
  Source,
  Layer,
  LineLayer,
  CircleLayer,
  NavigationControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Feature } from "geojson";
import { JesprActivity } from "./Activity";

const MAPBOX_STYLE = "robertoehler/ckses8ubo25xx18nvizkw2e0l";

interface Props {
  activity: JesprActivity;
}

const ActivityMap: FunctionComponent<Props> = ({ activity }) => {
  const bounds: [[number, number], [number, number]] = [
    [90, 90],
    [-90, -90],
  ];
  for (const dp of activity.dataPoints) {
    if (dp.coordinates[0] < bounds[0][1]) {
      bounds[0][1] = dp.coordinates[0];
    }
    if (dp.coordinates[1] < bounds[0][0]) {
      bounds[0][0] = dp.coordinates[1];
    }
    if (dp.coordinates[0] > bounds[1][1]) {
      bounds[1][1] = dp.coordinates[0];
    }
    if (dp.coordinates[1] > bounds[1][0]) {
      bounds[1][0] = dp.coordinates[1];
    }
  }

  const lineFeature: Feature = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: activity.dataPoints.map((dp) =>
        dp.coordinates.slice().reverse(),
      ),
    },
  };

  const lineLayer: LineLayer = {
    id: "activity",
    type: "line",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#00f096",
      "line-width": 5,
    },
  };

  const pointFeature: Feature = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Point",
      coordinates: activity.dataPoints[
        activity.dataPoints.length - 1
      ].coordinates
        .slice()
        .reverse(),
    },
  };

  const circleLayer: CircleLayer = {
    id: "current-position",
    type: "circle",
    paint: {
      "circle-radius": 7,
      "circle-color": "#00f096",
    },
  };

  return (
    <Map
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      initialViewState={{
        bounds,
        fitBoundsOptions: {
          padding: 50,
        },
      }}
      scrollZoom={false}
      mapStyle={`mapbox://styles/${MAPBOX_STYLE}`}
    >
      <Source type="geojson" data={lineFeature}>
        <Layer {...lineLayer} />
      </Source>
      {activity.active && activity.dataPoints.length > 0 && (
        <Source type="geojson" data={pointFeature}>
          <Layer {...circleLayer} />
        </Source>
      )}
      <NavigationControl />
    </Map>
  );
};

export default ActivityMap;
