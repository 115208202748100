import { FunctionComponent } from "react";
import Typography from "@mui/material/Typography";
import {
  Area,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { distanceBetween } from "../../utils/coordinates";
import { durationString } from "../../utils/time";
import { JesprActivity } from "./Activity";

interface ChartDataPoint {
  time: number;
  distance: number;
  elevation: number;
  speed?: number;
  power: number;
  cadence: number;
  heartRate: number;
}

const CHART_HEIGHT = 300;

interface Props {
  activity: JesprActivity;
  isIndoor: boolean;
}

const Charts: FunctionComponent<Props> = ({ activity, isIndoor }) => {
  const start =
    activity.dataPoints.length > 0
      ? Number(activity.dataPoints[0].timestamp)
      : 0;

  const data: ChartDataPoint[] = [];
  let distance = 0;
  let showSpeed = false;
  let showPower = false;
  let showCadence = false;
  let showHeartRate = false;
  let showElevation = false;
  activity.dataPoints.forEach((dp, i) => {
    if (!showElevation && dp.elevation) {
      showElevation = true;
    }
    if (!showSpeed && dp.speed) {
      showSpeed = true;
    }
    if (!showPower && dp.power) {
      showPower = true;
    }
    if (!showCadence && dp.cadence) {
      showCadence = true;
    }
    if (!showHeartRate && dp.heartRate) {
      showHeartRate = true;
    }
    data[i] = {
      time: Number(dp.timestamp) - start,
      distance: distance / 1000,
      elevation: dp.elevation,
      speed: dp.speed ? dp.speed * 3.6 : undefined,
      power: dp.power,
      cadence: dp.cadence,
      heartRate: dp.heartRate,
    };
    if (i < activity.dataPoints.length - 1) {
      distance += distanceBetween(
        activity.dataPoints[i].coordinates,
        activity.dataPoints[i + 1].coordinates,
      );
    }
  });

  return (
    <>
      {showSpeed && (
        <>
          <Typography variant="h6" paragraph>
            Speed
          </Typography>
          <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
            <ComposedChart data={data} syncId="sync">
              {isIndoor ? (
                <XAxis
                  type="number"
                  dataKey="time"
                  name="Time"
                  domain={["0", "dataMax"]}
                  tickFormatter={(t) => durationString(t)}
                />
              ) : (
                <XAxis
                  type="number"
                  dataKey="distance"
                  name="Distance"
                  unit=" km"
                  domain={[0, "dataMax"]}
                  tickFormatter={(t) => Math.round(t).toString()}
                />
              )}
              <YAxis yAxisId="left" dataKey="speed" name="Speed" unit=" km/h" />
              {showElevation && (
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  dataKey="elevation"
                  name="Elevation"
                  unit=" hm"
                  domain={["dataMin", "dataMax"]}
                  tickFormatter={(t) => Math.round(t).toString()}
                />
              )}
              <Line
                yAxisId="left"
                name={activity.user.name}
                type="monotone"
                dataKey="speed"
                stroke="#00f096"
                dot={false}
                connectNulls
                strokeWidth={2}
              />
              {showElevation && (
                <Area
                  yAxisId="right"
                  type="monotone"
                  dataKey="elevation"
                  name="Elevation"
                  stroke="#007047"
                  fill="rgba(192,192,192,0.3)"
                  strokeWidth={0}
                />
              )}
              <Legend />
              <Tooltip
                labelFormatter={(v) =>
                  isIndoor ? durationString(v) : `${Number(v).toFixed(2)} km`
                }
                formatter={(v, n) =>
                  n === "Elevation"
                    ? `${Math.round(Number(v))} hm`
                    : `${Number(v).toFixed(2)} km/h`
                }
                contentStyle={{ backgroundColor: "DarkSlateGray" }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}

      {showPower && (
        <>
          <Typography variant="h6" paragraph>
            Power
          </Typography>
          <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
            <ComposedChart data={data} syncId="sync">
              {isIndoor ? (
                <XAxis
                  type="number"
                  dataKey="time"
                  name="Time"
                  domain={["0", "dataMax"]}
                  tickFormatter={(t) => durationString(t)}
                />
              ) : (
                <XAxis
                  type="number"
                  dataKey="distance"
                  name="Distance"
                  unit=" km"
                  domain={[0, "dataMax"]}
                  tickFormatter={(t) => Math.round(t).toString()}
                />
              )}
              <YAxis yAxisId="left" dataKey="power" name="Power" unit=" W" />
              {showElevation && (
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  dataKey="elevation"
                  name="Elevation"
                  unit=" hm"
                  domain={["dataMin", "dataMax"]}
                />
              )}
              <Line
                yAxisId="left"
                name={activity.user.name}
                type="monotone"
                dataKey="power"
                stroke="#00f096"
                dot={false}
                strokeWidth={2}
              />
              {showElevation && (
                <Area
                  yAxisId="right"
                  type="monotone"
                  dataKey="elevation"
                  name="Elevation"
                  stroke="#007047"
                  fill="rgba(192,192,192,0.3)"
                  strokeWidth={0}
                />
              )}
              <Legend />
              <Tooltip
                labelFormatter={(v) =>
                  isIndoor ? durationString(v) : `${Number(v).toFixed(2)} km`
                }
                formatter={(v, n) => {
                  if (n === "Elevation") {
                    return `${Math.round(Number(v))} hm`;
                  }
                  return `${Math.round(Number(v))} W`;
                }}
                contentStyle={{ backgroundColor: "DarkSlateGray" }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}

      {showCadence && (
        <>
          <Typography variant="h6" paragraph>
            Cadence
          </Typography>
          <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
            <ComposedChart data={data} syncId="sync">
              {isIndoor ? (
                <XAxis
                  type="number"
                  dataKey="time"
                  name="Time"
                  domain={["0", "dataMax"]}
                  tickFormatter={(t) => durationString(t)}
                />
              ) : (
                <XAxis
                  type="number"
                  dataKey="distance"
                  name="Distance"
                  unit=" km"
                  domain={[0, "dataMax"]}
                  tickFormatter={(t) => Math.round(t).toString()}
                />
              )}
              <YAxis
                yAxisId="left"
                dataKey="cadence"
                name="Cadence"
                unit=" /min"
              />
              {showElevation && (
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  dataKey="elevation"
                  name="Elevation"
                  unit=" hm"
                  domain={["dataMin", "dataMax"]}
                />
              )}
              <Line
                yAxisId="left"
                name={activity.user.name}
                type="monotone"
                dataKey="cadence"
                stroke="#00f096"
                dot={false}
                strokeWidth={2}
              />
              {showElevation && (
                <Area
                  yAxisId="right"
                  type="monotone"
                  dataKey="elevation"
                  name="Elevation"
                  stroke="#007047"
                  fill="rgba(192,192,192,0.3)"
                  strokeWidth={0}
                />
              )}
              <Legend />
              <Tooltip
                labelFormatter={(v) =>
                  isIndoor ? durationString(v) : `${Number(v).toFixed(2)} km`
                }
                formatter={(v, n) => {
                  if (n === "Elevation") {
                    return `${Math.round(Number(v))} hm`;
                  }
                  return `${Math.round(Number(v))}/min`;
                }}
                contentStyle={{ backgroundColor: "DarkSlateGray" }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}

      {showHeartRate && (
        <>
          <Typography variant="h6" paragraph>
            Heart Rate
          </Typography>
          <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
            <ComposedChart data={data} syncId="sync">
              {isIndoor ? (
                <XAxis
                  type="number"
                  dataKey="time"
                  name="Time"
                  domain={["0", "dataMax"]}
                  tickFormatter={(t) => durationString(t)}
                />
              ) : (
                <XAxis
                  type="number"
                  dataKey="distance"
                  name="Distance"
                  unit=" km"
                  domain={[0, "dataMax"]}
                  tickFormatter={(t) => Math.round(t).toString()}
                />
              )}
              <YAxis
                yAxisId="left"
                dataKey="heartRate"
                name="Heart Rate"
                unit=" bpm"
              />
              {showElevation && (
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  dataKey="elevation"
                  name="Elevation"
                  unit=" hm"
                  domain={["dataMin", "dataMax"]}
                />
              )}
              <Line
                yAxisId="left"
                name={activity.user.name}
                type="monotone"
                dataKey="heartRate"
                stroke="#00f096"
                dot={false}
                strokeWidth={2}
              />
              {showElevation && (
                <Area
                  yAxisId="right"
                  type="monotone"
                  dataKey="elevation"
                  name="Elevation"
                  stroke="#007047"
                  fill="rgba(192,192,192,0.3)"
                  strokeWidth={0}
                />
              )}
              <Legend />
              <Tooltip
                labelFormatter={(v) =>
                  isIndoor ? durationString(v) : `${Number(v).toFixed(2)} km`
                }
                formatter={(v, n) => {
                  if (n === "Elevation") {
                    return `${Math.round(Number(v))} hm`;
                  }
                  return `${v} bpm`;
                }}
                contentStyle={{ backgroundColor: "DarkSlateGray" }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}
    </>
  );
};

export default Charts;
