import { FunctionComponent } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

interface Props {
  error: Error;
}

const ErrorMessage: FunctionComponent<Props> = ({ error }) => (
  <Snackbar
    open={true}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    message={error.message || "An unknown error occurred"}
    action={[
      <Button
        key="back"
        color="primary"
        size="small"
        onClick={() => {
          window.location.replace("/");
        }}
      >
        Reload Page
      </Button>,
    ]}
  />
);

export default ErrorMessage;
