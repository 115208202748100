import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { JesprRoute } from "./Route/Route";
import PlatformIcon from "../platforms/PlatformIcon";

const MAPBOX_STYLE = "robertoehler/ckses42lz4sw117nzs988o03v";
const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 400,
    },
    placeholder: {
      height: 400,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    icon: {
      height: "0.5em",
    },
    active: {
      color: theme.palette.primary.main,
    },
  }),
);

interface Props {
  routes: JesprRoute[];
}

const RoutesList: FunctionComponent<Props> = ({ routes }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {routes.map(
        (r) =>
          r.id !== "null" && (
            <Grid key={r.id} item xs={12} md={6} lg={4} xl={3}>
              <Link to={{ pathname: `/routes/${r.id}` }}>
                <Card className="jespr-hoverable">
                  {r.dataPointsPolyline ? (
                    <CardMedia
                      title={r.name}
                      image={`https://api.mapbox.com/styles/v1/${MAPBOX_STYLE}/static/path-2.5+00f096-0.6(${encodeURIComponent(
                        r.dataPointsPolyline,
                      )})/auto/600x400@2x?access_token=${mapboxAccessToken}`}
                      className={classes.media}
                    />
                  ) : (
                    <div className={classes.placeholder} />
                  )}
                  <CardHeader
                    title={r.name}
                    subheader={`${new Date(
                      parseInt(r.createdAt, 10),
                    ).toLocaleDateString()}${
                      r.start
                        ? `, Scheduled for ${new Date(
                            Number(r.start),
                          ).toLocaleDateString()}`
                        : ""
                    }`}
                    action={<PlatformIcon id={r.source} />}
                  />
                  <CardContent>
                    <Typography>
                      <PinDropIcon className={classes.icon} />
                      {(r.distance / 1000).toFixed(2)} km
                    </Typography>
                    <Typography>
                      <FilterHdrIcon className={classes.icon} />{" "}
                      {Math.round(r.elevationGain)} hm
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ),
      )}
    </Grid>
  );
};

export default RoutesList;
