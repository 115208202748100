import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import logo from "./logo.svg";

const useStyles = makeStyles({
  img: {
    height: 40,
    paddingTop: 2,
  },
});

const Logo: FunctionComponent = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Button>
      <img
        className={classes.img}
        src={logo}
        alt="Jespr"
        onClick={() => {
          navigate("/");
        }}
      />
    </Button>
  );
};

export default Logo;
