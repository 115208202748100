import { FunctionComponent } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Loading: FunctionComponent = () => (
  <div style={{ marginTop: "4em", textAlign: "center" }}>
    <CircularProgress
      style={{
        display: "inline-block",
        position: "relative",
      }}
    />
  </div>
);

export default Loading;
