import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import auth from "../Auth/Auth";

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = auth.getAccessToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
      },
    };
  });
  return forward(operation);
});

const onErrorLink = onError(({ networkError }) => {
  if (networkError) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_at");
    window.location.replace("/login");
  }
});

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URI || ""}/admin/graphql`,
});

const AdminApolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: onErrorLink.concat(authLink).concat(httpLink),
  credentials: "include",
});

export default AdminApolloClient;
