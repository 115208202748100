import { FunctionComponent, ChangeEvent, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers";
import { gql, useQuery, useMutation } from "@apollo/client";
import { JesprUser } from "../../User/User";
import { JesprWorkout } from "../Workout/Workout";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { GET_MY_WORKOUTS } from "../Workouts";
import { GET_MY_ROUTES } from "../../routes/Routes";

interface CreateWorkoutData {
  createWorkout: JesprWorkout;
}

interface CreateWorkoutVars {
  name: string;
  start: string;
  tss: number;
  routeId?: string | null;
}

const CREATE_WORKOUT = gql`
  mutation CreateWorkout(
    $name: String!
    $start: String!
    $tss: Int!
    $routeId: ID
  ) {
    createWorkout(
      name: $name
      start: $start
      tss: $tss
      routeId: $routeId
      segments: []
    ) {
      id
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(6),
    },
    dropzone: {
      textAlign: "center",
      "&:hover": {
        backgroundColor: "WhiteSmoke",
        cursor: "pointer",
      },
    },
    placeholder: {
      marginTop: theme.spacing(4),
      textAlign: "center",
      color: "gray",
    },
  }),
);

interface Props {
  isOpen: boolean;
  onClose(): void;
}

const CreateWorkoutDialog: FunctionComponent<Props> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [start, setStart] = useState<Dayjs>(dayjs());
  const [tss, setTSS] = useState(0);
  const [routeID, setRouteID] = useState<string | null>(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { error: getRoutesError, data } = useQuery<{ me: JesprUser }>(
    GET_MY_ROUTES,
  );
  const [createWorkout, { error: createWorkoutError }] = useMutation<
    CreateWorkoutData,
    CreateWorkoutVars
  >(CREATE_WORKOUT, {
    variables: {
      name,
      start: start.valueOf().toString(),
      tss,
      routeId: routeID,
    },
    refetchQueries: [GET_MY_WORKOUTS],
    onCompleted: () => {
      handleClose();
      setIsConfirmationOpen(true);
    },
    onError: (err) => {
      setError(err);
    },
  });

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleTSSChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTSS(Number(event.target.value));
  };

  const handleConfirmationClose = () => {
    setIsConfirmationOpen(false);
  };

  const handleErrorClose = () => {
    setError(null);
  };

  const handleClose = () => {
    setName("");
    setStart(dayjs());
    setRouteID(null);
    onClose();
  };

  if (getRoutesError) {
    return <ErrorMessage error={getRoutesError} />;
  }
  if (createWorkoutError) {
    return <ErrorMessage error={createWorkoutError} />;
  }

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>Create Workout</DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            id="name"
            label="Name"
            fullWidth
            autoFocus
            value={name}
            onChange={handleNameChange}
            className={classes.input}
          />

          <DateTimePicker
            label="Start Time (Optional)"
            value={start}
            onChange={(date) => {
              if (date) {
                setStart(date);
              }
            }}
            className={classes.input}
          />

          <TextField
            variant="standard"
            id="tss"
            label="TSS"
            type="number"
            fullWidth
            autoFocus
            value={tss}
            onChange={handleTSSChange}
            className={classes.input}
          />

          {data && data.me && (
            <Select
              variant="standard"
              label="Route (Optional)"
              fullWidth
              value={routeID}
              onChange={(e) => {
                setRouteID(e.target.value as string);
              }}
              className={classes.input}
            >
              <MenuItem value={""}>None</MenuItem>
              {data.me.routes.map((r) => (
                <MenuItem value={r.id}>
                  {r.name}{" "}
                  {r.start &&
                    `(${new Date(Number(r.start)).toLocaleDateString()})`}
                </MenuItem>
              ))}
            </Select>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            color="primary"
            disabled={name.length < 2}
            onClick={() => {
              createWorkout();
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={isConfirmationOpen}
        message="Workout created"
        autoHideDuration={4000}
        onClose={handleConfirmationClose}
      />

      <Snackbar
        open={Boolean(error)}
        message={error ? error : ""}
        onClose={handleErrorClose}
      />
    </>
  );
};

export default CreateWorkoutDialog;
