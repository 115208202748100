import { FunctionComponent } from "react";
import { ReactComponent as JesprIcon } from "./jespr.svg";
import { ReactComponent as StravaIcon } from "./strava.svg";
import { ReactComponent as KomootIcon } from "./komoot.svg";
import { ReactComponent as TrainingPeaksIcon } from "./trainingPeaks.svg";

const PlatformIcon: FunctionComponent<{ id: string }> = ({ id }) => {
  switch (id) {
    case "STRAVA":
      return <StravaIcon />;
    case "KOMOOT":
      return <KomootIcon />;
    case "TRAINING_PEAKS":
      return <TrainingPeaksIcon />;
    default:
      return <JesprIcon />;
  }
};

export default PlatformIcon;
