export interface Platform {
  id: string;
  name: string;
  authURI: string;
  authClientID: string;
  authScope: string;
}

export const platforms: Platform[] = [
  {
    id: "STRAVA",
    name: "Strava",
    authURI: "www.strava.com/oauth/authorize",
    authClientID: "18557",
    authScope: "read_all,activity:write",
  },
  {
    id: "KOMOOT",
    name: "Komoot",
    authURI: "auth-api.main.komoot.net/oauth/authorize",
    authClientID: "jespr-kp5kbk",
    authScope: "profile",
  },
  {
    id: "TRAINING_PEAKS",
    name: "TrainingPeaks",
    authURI: "oauth.trainingpeaks.com/OAuth/Authorize",
    authClientID: "jespr",
    authScope: "athlete:profile workouts:wod file:write",
  },
];
