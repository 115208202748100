import { FunctionComponent } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import auth from "../Auth/Auth";

const PrivateRoutes: FunctionComponent = () => {
  const location = useLocation();

  return auth.isAuthenticated() ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default PrivateRoutes;
