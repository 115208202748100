import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";
import auth from "../Auth/Auth";

const Login: FunctionComponent = () => {
  if (!auth.isAuthenticated()) {
    auth.login();
    return null;
  }

  return <Navigate to="/" />;
};

export default Login;
