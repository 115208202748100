import { FunctionComponent, MouseEvent, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fab from "@mui/material/Fab";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { gql, useQuery, useMutation } from "@apollo/client";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import Layout from "../../Layout/Layout";
import Loading from "../../Loading/Loading";
import { JesprUser } from "../../User/User";
import { gpxFromActivity } from "../../utils/gpx";
import { GET_MY_ACTIVITIES } from "../Activities";
import { JesprBike, JesprBikeCategory } from "./Bike";
import Map from "./Map";
import Charts from "./Charts";
import PlatformIcon from "../../platforms/PlatformIcon";

export interface JesprActivity {
  id: string;
  name: string;
  active: boolean;
  laps: JesprLap[];
  dataPoints: JesprDataPoint[];
  dataPointsPolyline: string;
  start: Date;
  distance: number;
  time: number;
  elevationGain: number;
  platformSyncs: JesprPlatformSync[];
  user: JesprUser;
  bike?: JesprBike;
}

export interface JesprLap {
  index: number;
  finalTime: number;
  finalDistance: number;
  finalElevationGain: number;
}

interface JesprPlatformSync {
  platformId: string;
  externalId: string;
}

export interface JesprDataPoint {
  lap: number;
  timestamp: string;
  coordinates: number[];
  elevation: number;
  temperature: number;
  gradient: number;
  speed?: number;
  power: number;
  cadence: number;
  heartRate: number;
  torqueEfficiency: {
    left: number;
    right: number;
  };
  pedalBalance: {
    left: number;
    right: number;
  };
  pedalSmoothness: {
    left: number;
    right: number;
  };
}

interface ActivityData {
  activity: JesprActivity;
  me: JesprUser;
}

interface ActivityVars {
  id: string;
}

const GET_ACTIVITY = gql`
  query GetActivity($id: ID!) {
    activity(id: $id) {
      id
      name
      active
      user {
        id
        name
      }
      bike {
        category
      }
      dataPoints {
        id
        cadence
        coordinates
        elevation
        gradient
        heartRate
        pedalBalance {
          left
          right
        }
        pedalSmoothness {
          left
          right
        }
        power
        speed
        temperature
        timestamp
        torqueEfficiency {
          left
          right
        }
      }
      platformSyncs {
        platformId
        externalId
      }
    }
    me {
      id
    }
  }
`;

const DELETE_ACTIVITY = gql`
  mutation DeleteActivity($id: ID!) {
    deleteActivity(id: $id)
  }
`;

let clientHeight = 0;
if (document.documentElement) {
  clientHeight = document.documentElement.clientHeight;
}
const windowHeight = Math.max(clientHeight, window.innerHeight || 0);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {
      height: windowHeight - 200,
      marginBottom: theme.spacing(5),
    },
    deleteItem: {
      color: "red",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      position: "fixed",
      bottom: theme.spacing(4),
      left: theme.spacing(4),
      zIndex: 3,
    },
  }),
);

const Activity: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { activityID } = useParams();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { loading, error, data } = useQuery<ActivityData, ActivityVars>(
    GET_ACTIVITY,
    { variables: { id: activityID || "" } },
  );
  const [deleteActivity, { error: deleteError }] = useMutation<
    boolean,
    { id: string }
  >(DELETE_ACTIVITY, {
    variables: { id: activityID || "" },
    refetchQueries: [GET_MY_ACTIVITIES],
    onCompleted: () => {
      navigate("/activities");
    },
  });

  const handleOpenActions = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleDownload = (activity: JesprActivity) => {
    const gpx = gpxFromActivity(activity);
    const element = document.createElement("a");
    const file = new Blob([gpx], { type: "application/gpx+xml" });
    element.href = URL.createObjectURL(file);
    element.download = `${activity.name}.gpx`;
    element.click();
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (deleteError) {
    return <ErrorMessage error={deleteError} />;
  }
  if (!data || !data.activity || !data.me) {
    throw Error("missing data");
  }

  const isIndoor = Boolean(
    data.activity.bike &&
      data.activity.bike.category === JesprBikeCategory.Indoor,
  );

  return (
    <>
      <Layout>
        <>
          <Grid container justifyContent="space-between">
            <Grid item sm={6}>
              <Typography variant="h5" paragraph>
                {data.activity.name}
              </Typography>
            </Grid>
            <Grid item sm={6} className={classes.buttonContainer}>
              {data.me.id === data.activity.user.id && (
                <>
                  <div style={{ marginRight: "2em" }}>
                    {data.activity.platformSyncs
                      .filter((sp) => sp.externalId)
                      .map((sp) => (
                        <PlatformIcon id={sp.platformId} />
                      ))}
                  </div>
                  <Button
                    color="primary"
                    onClick={handleOpenActions}
                    style={{ marginTop: -5 }}
                  >
                    Actions
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseActions}
                  >
                    <MenuItem
                      onClick={() => {
                        handleDownload(data.activity);
                      }}
                    >
                      <CloudDownloadIcon className={classes.icon} /> Download as
                      GPX
                    </MenuItem>
                    <MenuItem
                      className={classes.deleteItem}
                      onClick={() => {
                        deleteActivity();
                      }}
                    >
                      <DeleteIcon className={classes.icon} /> Delete
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Grid>
          </Grid>

          <div className={classes.mapContainer}>
            <Map activity={data.activity} />
          </div>

          <Charts activity={data.activity} isIndoor={isIndoor} />
        </>
      </Layout>

      <Fab
        aria-label="Go back"
        className={classes.backButton}
        onClick={() => {
          navigate("/activities");
        }}
      >
        <ArrowBackIcon />
      </Fab>
    </>
  );
};

export default Activity;
