import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import PinDropIcon from "@mui/icons-material/PinDrop";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TimerIcon from "@mui/icons-material/Timer";
import { JesprActivity } from "./Activity/Activity";
import { durationString } from "../utils/time";

const MAPBOX_STYLE = "robertoehler/ckses42lz4sw117nzs988o03v";
const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 400,
    },
    placeholder: {
      height: 400,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    icon: {
      height: "0.5em",
    },
  }),
);

interface Props {
  activities: JesprActivity[];
}

const ActivitiesList: FunctionComponent<Props> = ({ activities }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {activities.map(
        (a) =>
          a.id !== "null" && (
            <Grid key={a.id} item xs={12} md={6} lg={4} xl={3}>
              <Link to={`/activities/${a.id}`}>
                <Card className="jespr-hoverable">
                  {a.dataPointsPolyline ? (
                    <CardMedia
                      className={classes.media}
                      image={`https://api.mapbox.com/styles/v1/${MAPBOX_STYLE}/static/path-2.5+00f096-0.6(${encodeURIComponent(
                        a.dataPointsPolyline,
                      )})/auto/600x400@2x?access_token=${mapboxAccessToken}`}
                      title={a.name}
                    />
                  ) : (
                    <div className={classes.placeholder} />
                  )}
                  <CardHeader
                    title={a.name}
                    subheader={
                      a.start
                        ? new Date(Number(a.start)).toLocaleDateString()
                        : "Not started yet"
                    }
                  />
                  <CardContent>
                    <Typography>
                      <PinDropIcon className={classes.icon} />
                      {(a.distance / 1000).toFixed(2)} km
                    </Typography>
                    <Typography>
                      <ScheduleIcon className={classes.icon} />
                      {durationString(a.time)}
                    </Typography>
                    <Typography>
                      <TimerIcon className={classes.icon} />
                      {(a.time
                        ? (a.distance / (a.time / 1000)) * 3.6
                        : 0
                      ).toFixed(2)}{" "}
                      km/h
                    </Typography>
                    <Typography>
                      <FilterHdrIcon className={classes.icon} />{" "}
                      {a.elevationGain} hm
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ),
      )}
    </Grid>
  );
};

export default ActivitiesList;
