const degreesToRadians = (degrees: number) => (degrees * Math.PI) / 180;

export const distanceBetween = (pos1: number[], pos2: number[]) => {
  const earthRadius = 6371000;

  const dLat = degreesToRadians(pos2[0] - pos1[0]);
  const dLon = degreesToRadians(pos2[1] - pos1[1]);

  const lat1 = degreesToRadians(pos1[0]);
  const lat2 = degreesToRadians(pos2[0]);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * 2;
  return earthRadius * c;
};
