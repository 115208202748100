import { FunctionComponent, MouseEvent, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fab from "@mui/material/Fab";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Typography from "@mui/material/Typography";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { gql, useQuery, useMutation } from "@apollo/client";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import Layout from "../../Layout/Layout";
import Loading from "../../Loading/Loading";
import { JesprUser } from "../../User/User";
import { JesprRoute } from "../../routes/Route/Route";
import { gpxFromRoute } from "../../utils/gpx";
import Map from "../../routes/Route/Map";
import Charts from "./Charts";
import { GET_MY_WORKOUTS } from "../Workouts";

export interface JesprWorkout {
  id: string;
  name: string;
  start?: string;
  tss: number;
  source: string;
  route?: JesprRoute;
  user: JesprUser;
}

interface WorkoutData {
  workout: JesprWorkout;
  me: JesprUser;
}

interface WorkoutVars {
  id: string;
  source?: string;
}

const GET_WORKOUT = gql`
  query GetWorkout($id: ID!, $source: PlatformID) {
    workout(id: $id, source: $source) {
      id
      name
      source
      route {
        dataPoints {
          coordinates
          elevation
        }
      }
    }
    me {
      id
    }
  }
`;

const DELETE_WORKOUT = gql`
  mutation DeleteWorkout($id: ID!) {
    deleteWorkout(id: $id)
  }
`;

let clientHeight = 0;
if (document.documentElement) {
  clientHeight = document.documentElement.clientHeight;
}
const windowHeight = Math.max(clientHeight, window.innerHeight || 0);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {
      height: windowHeight - 200,
      marginBottom: theme.spacing(5),
    },
    active: {
      color: theme.palette.primary.main,
    },
    deleteItem: {
      color: "red",
    },
    buttonContainer: {
      textAlign: "right",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      position: "fixed",
      bottom: theme.spacing(4),
      left: theme.spacing(4),
      zIndex: 3,
    },
  }),
);

const Workout: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { workoutID } = useParams();
  const [searchParams] = useSearchParams();
  const source = searchParams.get("source");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { loading, error, data } = useQuery<WorkoutData, WorkoutVars>(
    GET_WORKOUT,
    {
      variables: { id: workoutID || "", source: source ? source : undefined },
    },
  );
  const [deleteWorkout, { error: deleteError }] = useMutation<
    boolean,
    { id: string }
  >(DELETE_WORKOUT, {
    variables: { id: workoutID || "" },
    refetchQueries: [GET_MY_WORKOUTS],
    onCompleted: () => {
      navigate("/workouts");
    },
  });

  const handleOpenActions = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleDownload = (workout: JesprWorkout) => {
    if (!workout.route) {
      return;
    }
    const gpx = gpxFromRoute(workout.route);
    const element = document.createElement("a");
    const file = new Blob([gpx], { type: "application/gpx+xml" });
    element.href = URL.createObjectURL(file);
    element.download = `${workout.name}.gpx`;
    element.click();
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (deleteError) {
    return <ErrorMessage error={deleteError} />;
  }
  if (!data || !data.workout || !data.me) {
    throw Error("missing data");
  }

  return (
    <>
      <Layout>
        <>
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="h5" paragraph>
                {data.workout.name}
              </Typography>
            </Grid>
            {data.workout.source === "JESPR" && (
              <Grid item sm={6} className={classes.buttonContainer}>
                <Button color="primary" onClick={handleOpenActions}>
                  Actions
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseActions}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownload(data.workout);
                    }}
                  >
                    <CloudDownloadIcon className={classes.icon} /> Download as
                    GPX
                  </MenuItem>
                  {(!data.workout.source ||
                    data.workout.source === "JESPR") && (
                    <MenuItem
                      className={classes.deleteItem}
                      onClick={() => {
                        deleteWorkout();
                      }}
                    >
                      <DeleteIcon className={classes.icon} /> Delete
                    </MenuItem>
                  )}
                </Menu>
              </Grid>
            )}
          </Grid>

          {data.workout.route && (
            <div className={classes.mapContainer}>
              <Map route={data.workout.route} />
            </div>
          )}

          <Charts workout={data.workout} />
        </>
      </Layout>

      <Fab
        aria-label="Go back"
        className={classes.backButton}
        onClick={() => {
          navigate("/workouts");
        }}
      >
        <ArrowBackIcon />
      </Fab>
    </>
  );
};

export default Workout;
