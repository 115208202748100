import auth0, { Auth0DecodedHash } from "auth0-js";

const AUTH0_DOMAIN = "jespr.eu.auth0.com";

class Auth {
  private tokenRenewalTimeout: number | null = null;
  private readonly auth0 = new auth0.WebAuth({
    domain: AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
    redirectUri: `${window.location.protocol}//${window.location.host}/callback`,
    audience: `${process.env.REACT_APP_API_URI || ""}`,
    responseType: "token",
    scope: "openid email jespr.write",
  });

  constructor() {
    this.scheduleRenewal();
  }

  readonly login = () => {
    this.auth0.authorize();
  };

  readonly handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (err) {
        window.location.replace("/login");
        throw err;
      }
      if (!authResult || !authResult.accessToken) {
        window.location.replace("/login");
        return;
      }
      this.setSession(authResult);
    });
  };

  readonly getAccessToken = () => localStorage.getItem("access_token") || "";

  readonly logout = () => {
    // Clear access token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_at");

    if (this.tokenRenewalTimeout) {
      clearTimeout(this.tokenRenewalTimeout);
    }

    this.auth0.logout({
      returnTo: `${window.location.protocol}//${window.location.host}/login`,
    });
  };

  readonly isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = localStorage.getItem("expires_at");
    if (!expiresAt) {
      return false;
    }

    return Date.now() < parseInt(expiresAt, 10);
  };

  private readonly setSession = (authResult: Auth0DecodedHash) => {
    // Set the time that the access token will expire at
    if (!authResult.expiresIn || !authResult.accessToken) {
      throw Error("invalid auth result");
    }

    const expiresAt = (authResult.expiresIn * 1000 + Date.now()).toString();
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("expires_at", expiresAt);

    this.scheduleRenewal();

    window.location.replace("/");
  };

  private readonly renewToken = () => {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        throw err;
      }
      this.setSession(result);
    });
  };

  private readonly scheduleRenewal = () => {
    const expiresAt = localStorage.getItem("expires_at");
    if (!expiresAt) {
      this.renewToken();
      return;
    }

    const delay = parseInt(expiresAt, 10) - Date.now();
    if (delay <= 0) {
      this.renewToken();
      return;
    }

    this.tokenRenewalTimeout = window.setTimeout(() => {
      this.renewToken();
    }, delay);
  };
}

const auth = new Auth();

export default auth;
