import { FunctionComponent } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Activities from "./activities/Activities";
import Activity from "./activities/Activity/Activity";
import JesprRoutes from "./routes/Routes";
import JesprRoute from "./routes/Route/Route";
import JesprWorkouts from "./workouts/Workouts";
import JesprWorkout from "./workouts/Workout/Workout";
import auth from "./Auth/Auth";
import Callback from "./Callback/Callback";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import Login from "./Login/Login";
import Me from "./Me/Me";
import PrivateRoutes from "./RequireAuth/RequireAuth";
import User from "./User/User";
import PlatformAuthCallback from "./platforms/PlatformAuthCallback";
import UserInfoTable from "./admin/UserInfoTable";

const authLink = setContext((_, { headers }) => {
  const token = auth.getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ networkError }) => {
  if (networkError && networkError.message.includes("401")) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_at");
    window.location.replace("/login");
  }
});

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URI || ""}/graphql`,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authLink, errorLink, httpLink]),
});

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#00f096",
    },
  },
  typography: {
    fontFamily: "ABC Diatype Plus Variable",
  },
});

const App: FunctionComponent = () => (
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/activities" replace />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/callback" element={<Callback />} />
                <Route path="/" element={<PrivateRoutes />}>
                  <Route path="/activities" element={<Activities />} />
                  <Route
                    path="/activities/:activityID"
                    element={<Activity />}
                  />
                  <Route path="/routes" element={<JesprRoutes />} />
                  <Route path="/routes/:routeID" element={<JesprRoute />} />
                  <Route path="/workouts" element={<JesprWorkouts />} />
                  <Route
                    path="/workouts/:workoutID"
                    element={<JesprWorkout />}
                  />
                  <Route path="/users/:userID" element={<User />} />
                  <Route path="/me" element={<Me />} />
                  <Route path="/admin/user-info" element={<UserInfoTable />} />
                  <Route
                    path="/platforms/:platformID/auth/callback"
                    element={<PlatformAuthCallback />}
                  />
                </Route>
              </Routes>
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </ApolloProvider>
  </ErrorBoundary>
);

export default App;
